import { capitalizeFirstLetter, formattedAddress } from "@/helpers/commons";
import i18n from "@/i18n";
import store from "@/store";
import { IAddress, IDegreeLevelDB, IWorkerJob } from "@/types";
import { parsePhoneNumber } from "libphonenumber-js";
import moment from "moment-timezone";

interface AskedChanges {
    birthPlace?: string;
    address?: IAddress & { department: string; region: string };
    firstname?: string;
    phone?: string;
    gender?: string;
    birthDate?: number;
    lastname?: string;
    jobs?: IWorkerJob[];
}

interface Item {
    field: string;
    oldValue: string | null;
    newValue: string | null;
}

interface IJobOptions {
    label: string;
    value: string;
    slug: string[];
    selectable: boolean;
}

export const useAskChangesFormater = () => {
    const jobOptions: IJobOptions[] = store.getters.jobOptions;
    const degreeOptions: { label: string; value: string }[] = store.getters.degreeLevels.map((degreeLevel: IDegreeLevelDB) => {
        return { label: degreeLevel.name, value: degreeLevel.slug };
    });
    const experienceOptions: { label: string; value: string }[] = store.getters.interimExperienceOptions;

    const formatJob = (jobToFormat?: IWorkerJob): string => {
        if (!jobToFormat) return "";
        const jobName = jobOptions.find((job) => job.value === jobToFormat.jobId)?.label;
        const degreeLevel = degreeOptions.find((degree) => degree.value === jobToFormat.degreeLevel || "")?.label;
        const experience = experienceOptions.find((experience) => experience.value === jobToFormat.experience)?.value;

        return [jobName, degreeLevel, experience ? i18n.t("jobExperienceShort." + experience).toString() : ""].filter(Boolean).join(" - ");
    };

    const askChangesFormater = (worker: any) => {
        const askChangesFormated: Item[] = [];
        for (const [key, value] of Object.entries(worker.askedChanges as AskedChanges)) {
            switch (key) {
                case "gender":
                    break;

                case "address":
                    const oldAddress = worker.address;

                    askChangesFormated.push({
                        field: key,
                        oldValue: formattedAddress(oldAddress as IAddress),
                        newValue: formattedAddress(value as IAddress),
                    });
                    break;

                case "birthDate":
                    askChangesFormated.push({
                        field: key,
                        oldValue: worker.birthDate,
                        newValue: moment(value).format("YYYY-MM-DD"),
                    });
                    break;

                case "phone":
                    askChangesFormated.push({
                        field: key,
                        oldValue: worker[key],
                        newValue: parsePhoneNumber(value, "FR").formatNational(),
                    });
                    break;

                case "jobs":
                    const newJobs = (value as IWorkerJob[]).filter((elem) => !(worker.jobs as IWorkerJob[]).some((job) => job.jobId === elem.jobId));
                    const deleteJobs = (worker.jobs as IWorkerJob[]).filter((elem) => !value.some((job: any) => job.jobId === elem.jobId));
                    const updatedJobs = (value as IWorkerJob[]).filter((elem) =>
                        (worker.jobs as IWorkerJob[]).some((job) => job.jobId === elem.jobId)
                    );
                    newJobs.forEach((job) =>
                        askChangesFormated.push({
                            field: key,
                            oldValue: null,
                            newValue: formatJob(job),
                        })
                    );
                    deleteJobs.forEach((job) =>
                        askChangesFormated.push({
                            field: key,
                            oldValue: formatJob(job),
                            newValue: null,
                        })
                    );
                    updatedJobs.forEach((job) => {
                        const newValue = formatJob(job);
                        const oldValue = formatJob((worker.jobs as IWorkerJob[]).find((wjob) => wjob.jobId === job.jobId));

                        if (newValue !== oldValue) {
                            askChangesFormated.push({
                                field: key,
                                oldValue,
                                newValue,
                            });
                        }
                    });
                    break;

                case "lastname":
                case "firstname":
                    askChangesFormated.push({
                        field: key,
                        oldValue: capitalizeFirstLetter(worker[key]),
                        newValue: capitalizeFirstLetter(value.toString()),
                    });
                    break;

                default:
                    askChangesFormated.push({
                        field: key,
                        oldValue: worker[key],
                        newValue: value.toString(),
                    });
                    break;
            }
        }

        return askChangesFormated;
    };

    return { askChangesFormater };
};
