export enum BadgeSize {
    sm = "sm",
    md = "md",
    lg = "lg",
    xl = "xl",
}

export enum BadgeColor {
    white = "white",
    neutral = "neutral",
    primary = "primary",
    purple = "purple",
    red = "red",
    orange = "orange",
    green = "green",
    yellow = "yellow",
    transparent = "transparent"
}

export interface BadgeProps {
    size?: keyof typeof BadgeSize;
    icon?: string;
    color?: keyof typeof BadgeColor;
    stroke?: boolean;
    label?: string;
    backgroundColor?: string;
    borderColor?: string;
    iconColor?: keyof typeof BadgeColor;
    fontColor?: string;
}
