import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=5f96aa84&scoped=true&"
import script from "./Card.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Card.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Card.vue?vue&type=style&index=0&id=5f96aa84&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f96aa84",
  null
  
)

export default component.exports