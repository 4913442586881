import { TranslateResult } from "vue-i18n";
import { BadgeProps } from "../Badge";
import { AvatarProps } from "../Avatar";
export { default } from "./Header.vue";

export interface HeaderProps {
    title: string,
    avatar?: AvatarProps,
    status?: BadgeProps;
    subtitle?: { icon?: string, subtitle?: string };
    badges?: BadgeAndTooltipItem[];
}

interface TooltipHeader {
    tooltipTitle?: string | TranslateResult;
    items?: { title?: string | TranslateResult; content: string }[];
    type: "card" | "badge";
}

export interface BadgeAndTooltipItem {
    badge: BadgeProps;
    tooltip?: TooltipHeader;
}
